// RoleServiceProvider.js
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useTenant } from "../../contexts/TenantService";

export const RoleServiceProvider = () => {
  const { authService } = useAuth();
  const { tenantSvc } = useTenant();
  const tenantIdentifier = tenantSvc?.tenantId;
  const token = authService?.getAccessToken();
  const reportUrl = `${tenantSvc?.reportersvcBaseUrl}/${tenantIdentifier}`;

  function getRoles(resource) {
    return axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function getRolesByOrganization(resource, selectedID) {
    if (tenantSvc) {
      return axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}?organization=` + selectedID, {
        headers: { Authorization: `bearer ${token}` },
      });
    }
  }

  function getRoleByID(resource, roleID) {
    const apiUrl = tenantSvc?.apiUrl;
    return axios.get(`${apiUrl}/${tenantIdentifier}/${resource}/${roleID}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }
  function getRoleAssignmentDetails() {
    if (!tenantSvc?.reportersvcBaseUrl) {
      return undefined;
    }

    const roleUrl = `${reportUrl}/user-role-assignment`;
    return axios
      .get(`${roleUrl}`, {
        headers: { Authorization: `bearer ${token}` },
      })
      .catch((error) => {
        throw error;
      });
  }

  function getUserLastLoginDetails(resource, userNames, dateFrom, dateTo) {
    let url = `${reportUrl}/${resource}?dateFrom=${dateFrom}&dateTo=${dateTo}`;

    if (Array.isArray(userNames) && userNames.length > 0) {
      userNames.forEach((userName) => {
        url += `&userName=${encodeURIComponent(userName)}`;
      });
    } else if (userNames) {
      url += `&userName=${encodeURIComponent(userNames)}`;
    }
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((error) => {
        throw error;
      });
  }
  function getAccessLogDetails(resource, userNames, startDate, endDate) {
    let url = `${reportUrl}/${resource}?startDate=${startDate}&endDate=${endDate}`;

    if (Array.isArray(userNames) && userNames.length > 0) {
      userNames.forEach((userName) => {
        url += `&userName=${encodeURIComponent(userName)}`;
      });
    } else if (userNames) {
      url += `&userName=${encodeURIComponent(userNames)}`;
    }
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((error) => {
        throw error;
      });
  }
  return {
    getRoles,
    getRoleByID,
    getRolesByOrganization,
    getRoleAssignmentDetails,
    getAccessLogDetails,
    getUserLastLoginDetails,
  };
};

export default RoleServiceProvider;
