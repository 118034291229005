// Enum for roles
export const Roles = {
  ADMIN: "ssciam-um-admin",
  PROFILE_VIEWER: "ssciam-um-profile",
  ACCESS_LOG_VIEWER: "ssciam-um-report-access-log",
  ADMIN_MT: "ssciam-um-admin-mt",
  PROFILE_VIEWER_MT: "ssciam-um-profile-mt",
  ACCESS_LOG_VIEWER_MT: "ssciam-rpt-accesslog",
  ACCESS_LOG_VIEWER_PSA: "ssciam-rpt-accesslogs-psa",
  PROFILE: "ssciam-um-profile",
  PROFILE_MT: "ssciam-um-profile-mt",
  ROLE_ASSIGNMENT_VIEWER: "ssciam-rpt-userroleassignment",
  ROLE_ASSIGNMENT_VIEWER_PSA: "ssciam-rpt-userroleassignment-psa",
  LAST_LOGIN_USER_VIEWER: "ssciam-rpt-lastlogin",
  PSA_LAST_LOGIN_USER_VIEWER: "ssciam-rpt-lastlogin_psa",
};
