function UserManagerErrorNotification(error, error_code, message) {
  let errorMsg = "";
  if (error_code != 500) {
    if (error?.error_description && error?.error_validations) {
      errorMsg = `Error: ${error?.error_validations.map((n) => n.message)}`;
    } else if (error?.error_description) {
      errorMsg = `Error: ${error?.error_description}`;
    } else {
      errorMsg = "Unknown error";
    }
    return message ? `${errorMsg}. ${message}` : errorMsg;
  } else {
    errorMsg = error.error.message;
    return errorMsg;
  }
}

export default UserManagerErrorNotification;
