export default class Filters {
  /**
   * @param {Object} annotatedData
   * @param {Array} annotatedData.data
   * @param {Object} annotatedData.filterCriteria
   * @param {string} annotatedData.filterCriteria.key
   * @param {string} annotatedData.filterCriteria.value
   * @returns {Array}
   *
   * @description This function filters the data based on the filterCriteria by comparing to check partial matches.
   * The filterCriteria is an object with two properties: key and value.
   * The key is the name of the column to filter by.
   * The value is the value to filter by. ex: { data: [{name: "John Doe"}, {name: "Jane Doe"}], filterCriteria: {key: "name", value: "John"} }
   */
  static simpleTextFilter = (annotatedData) => {
    const { data, filterCriteria } = annotatedData;

    if (
      !Array.isArray(data) ||
      typeof filterCriteria !== "object" ||
      Object.keys(filterCriteria).length === 0 ||
      typeof filterCriteria.key !== "string" ||
      typeof filterCriteria.value !== "string"
    ) {
      return [];
    }

    return data.filter((row) => this.includesIgnoreCase(row[filterCriteria.key], filterCriteria.value));
  };

  static includesIgnoreCase = (s1, s2) => {
    return (s1 ?? "").toUpperCase().includes((s2 ?? "").toUpperCase());
  };

  /**
   *
   * @param {object} annotatedData
   * @param {Array} annotatedData.data
   * @param {object} annotatedData.filterCriteria
   * @param {string} annotatedData.filterCriteria.key
   * @param {not a string} annotatedData.filterCriteria.value
   * @returns {Array}
   * @description This function filters the data based on the filterCriteria by comparing the values for an exact match.
   * The filterCriteria is an object with two properties: key and value.
   * The key is the name of the column to filter by.
   * The value is the value to filter by. ex: { data: [{name: "John Doe", age: 20}, {name: "Jane Doe", age: 22}], filterCriteria: {key: "age", value: 22} }
   */
  static exactMatch = (annotatedData) => {
    const { data, filterCriteria } = annotatedData;

    if (
      !Array.isArray(data) ||
      typeof filterCriteria !== "object" ||
      Object.keys(filterCriteria).length === 0 ||
      typeof filterCriteria.key !== "string"
    ) {
      return [];
    }

    return data.filter((row) => row[filterCriteria.key] === filterCriteria.value);
  };

  /**
   *
   * @param {object} annotatedData
   * @param {Array} annotatedData.data
   * @param {object} annotatedData.filterCriteria
   * @param {string} annotatedData.filterCriteria.key
   * @param {Array} annotatedData.filterCriteria.value
   * @returns {boolean}
   *
   * @description This function filters the data based on the filterCriteria by checking
   * if the array in the data item contains any of the values in the filterCriteria.
   * The filterCriteria is an object with two properties: key and value.
   * The key is the name of the column to filter by.
   * The value is the value to filter by.
   * ex: { data: [{name: "John Doe", hobbies: ["swimming", "running"]}, {name: "Jane Doe", hobbies: ["swimming", "biking"]}], filterCriteria: {key: "hobbies", value: ["swimming", "biking"]} }
   */
  static nestedArrayContentFilter = (annotatedData) => {
    const data = [annotatedData.row];
    const filterCriteria = annotatedData.filterobject;
    if (
      !Array.isArray(data) ||
      typeof filterCriteria !== "object" ||
      Object.keys(filterCriteria).length === 0 ||
      typeof filterCriteria.key !== "string" ||
      !Array.isArray(filterCriteria.value)
    ) {
      return [];
    }

    return data.filter((row) => this.includesAny(filterCriteria.value, row.original[filterCriteria.key]));
  };

  /**
   *
   * @param {object} annotatedData
   * @param {Array} annotatedData.data
   * @param {object} annotatedData.filterCriteria
   * @param {string} annotatedData.filterCriteria.key
   * @param {Array} annotatedData.filterCriteria.value
   * @returns {Array}

   * @description This function filters the data based on the filterCriteria by checking

   * if the specified key in the data item is available in the filterCriteria.value array as an exact match.

   * The filterCriteria is an object with two properties: key and value.

   * The key is the name of the column to filter by.

   * The value is the value to filter by.

   * ex: { data: [{name: "John Doe", age: 20}, {name: "Jane Doe", age: 22}], filterCriteria: {key: "name", value: ["John Doe", "Jane Doe"]} }

   */

  static filterArrays = (annotatedData) => {
    const data = annotatedData.roles_collection;
    const row = annotatedData.row;
    const filterCriteria = annotatedData.roleFc;
    const filterKey = annotatedData.filterKey;

    if (
      !Array.isArray(data) ||
      typeof filterCriteria !== "object" ||
      Object.keys(filterCriteria).length === 0 ||
      typeof filterCriteria.key !== "string" ||
      !typeof filterCriteria.value === "object"
    ) {
      return [];
    }

    const test = filterCriteria.value.map((item) => {
      return this.exactMatch({ data, filterCriteria: { key: "name", value: item } });
    });

    return this.nestedArrayContentFilter({
      row,
      filterobject: { key: filterKey, value: test.flatMap((innerArr) => innerArr.map((item) => item.id)) },
    });
  };

  static includesAny = (arr1, arr2) => {
    return arr1.some((item) => arr2?.includes(item));
  };
}
