import { Box, Button, Grid, Stack, TextField, Typography, InputAdornment, List, Alert } from "@mui/material";
import PropTypes from "prop-types";
import {
  CustomListItem,
  CustomParentBox,
  LeftBannerBoxParent,
  RightBannerBoxParent,
} from "../../features/CustomInputFields/CustomInputFields";
import "./CredMgmtSetPassword.css";
import { useFormik } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import {
  _accountActivationSuccess,
  _invalidTokenMessage,
  _unknownError,
  _validationError,
  validationSchema_confirmPassword,
  validationSchema_password,
} from "../../helpers";
import { CredentialMgmtDataService } from "../../services/CredentialMgmtDataService";
import { useLocation, useParams } from "react-router";
import { useConfig } from "../../contexts/ConfigContext";
import { CenterLoadingSpinner } from "../Spinners/LoadSpinner";
import StatusScreen from "../StatusScreen/StatusScreen";
import * as Yup from "yup";
import { useTenant } from "../../contexts/TenantService";
import { UserManagerErrorNotification } from "../../helpers/UserManagerErrorNotification";
import passwordPolicyTexts from "../../helpers/PasswordPolicyTexts/PasswordPolicyTexts";

const CredMgmtSetPassword = () => {
  const { search } = useLocation();
  const { tenant } = useParams();
  const [validStatus, setValidStatus] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState();
  const { config } = useConfig();
  const { tenantSvc } = useTenant();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  let token;
  if (search && search.split("=")[1]) {
    token = search.split("=")[1];
  }

  const credentialService = CredentialMgmtDataService();
  useEffect(() => {
    if (token) {
      credentialService
        .validateToken("/accounts/operations/pre-validate", { token })
        .then((response) => {
          setPasswordPolicy(response?.data?.passwordPolicy);
          setValidStatus({
            status: response.status,
            step: "pre-validation",
            message: "Success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setValidStatus({
              status: error.response.status,
              step: "pre-validation",
              message: "Invalid link",
            });
          }
          if (error.response.status === 500) {
            setValidStatus({
              status: error.response.status,
              step: "pre-validation",
              message: _unknownError,
            });
          }
        });
    } else {
      setValidStatus({
        status: 400,
        step: "pre-validation",
        message: "Invalid link",
      });
    }
  }, [token]);

  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirmedPassword) {
        errors.confirmedPassword = "Passwords do not match";
      }
      return errors;
    },
    initialValues: { password: "", confirmedPassword: "" },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: validationSchema_password(passwordPolicy).password(),
      confirmedPassword: validationSchema_confirmPassword.confirmPassword,
    }),
    onSubmit: (values) => {
      setIsloading(true);
      credentialService
        .activateUser("/accounts/operations/change-password", {
          ...values,
          token,
        })
        .then((response) => {
          setIsloading(false);
          setValidStatus({
            status: response.status,
            step: "activation",
            message: _accountActivationSuccess,
            goto: tenantSvc.authExitUrl,
          });
        })
        .catch((error) => {
          setIsloading(false);

          if (error?.response?.status >= 400 && error?.response?.status < 500) {
            const errorStatus = {
              code: error?.response?.status,
              message: UserManagerErrorNotification(error?.response?.data),
            };
            setValidStatus(errorStatus);
            setShowErrorBanner(true);
          }
          if (error.response.status === 400) {
            const errorMessages = error.response.data?.error_validations?.map((obj) => obj.message) ?? [];

            if (errorMessages.some((message) => message.toLowerCase() === "invalid token")) {
              setValidStatus({
                status: error.response.status,
                step: "activation",
                message: _invalidTokenMessage,
              });
            } else if (errorMessages) {
              setValidStatus({
                status: error.response.status,
                step: "activation",
                message: _validationError,
                validationErrors: errorMessages,
              });
            } else {
              setValidStatus({
                status: error.response.status,
                step: "activation",
                message: _unknownError,
              });
            }
          }
          if (error.response.status === 500) {
            setValidStatus({
              status: error.response.status,
              message: _unknownError,
            });
          }
        });
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  if (!config) {
    <CenterLoadingSpinner />;
  }
  if (!validStatus) {
    return <CenterLoadingSpinner />;
  }

  if (validStatus.status === 400 && !validStatus.validationErrors) {
    return <StatusScreen status={validStatus} />;
  }

  if (validStatus.status === 500) {
    return <StatusScreen status={validStatus} />;
  }

  if (validStatus.status === 200 && validStatus.step == "activation") {
    return <StatusScreen status={validStatus} />;
  }
  return (
    <CustomParentBox className="set-password-page cred-mgmt">
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={7} sx={{ height: "100%", ml: "0", p: "0", display: { xs: "none", sm: "block" } }}>
          <LeftBannerBoxParent className="left-box-wrapper">
            <Box
              className={tenant}
              id="worldline"
              component="img"
              alt="CredentialManagementBanner"
              src={process.env.PUBLIC_URL + `/static/images/${tenant}/CredsManagementBanner.png`}
            />
          </LeftBannerBoxParent>
        </Grid>
        <Grid item sm={5} className="right-wrapper" id={tenant}>
          <RightBannerBoxParent>
            <Box
              component="img"
              sx={{ height: 50 }}
              alt="logo"
              margin="10px"
              src={process.env.PUBLIC_URL + "/static/images/" + tenant + "/banner_logo.png"}
              data-testid="logo"
              className="logo-dynamic"
            />
            {isLoading ? (
              <div>
                <Typography variant="h5" className="header-5">
                  Setting Password
                </Typography>
                <CenterLoadingSpinner style={{ marginLeft: 0 }} />
              </div>
            ) : (
              <form>
                <Typography variant="h5" className="header-5">
                  Set password
                </Typography>

                {showErrorBanner ? (
                  <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={10}>
                      <Alert
                        severity="error"
                        onClose={() => {
                          setShowErrorBanner(false);
                        }}
                      >
                        {validStatus.message}
                      </Alert>
                    </Grid>
                    <Grid xs={1}></Grid>
                  </Grid>
                ) : null}
                <Stack sx={{ mt: "30px" }}>
                  <label className="input-label" htmlFor="password">
                    NEW PASSWORD
                  </label>
                  <TextField
                    name="password"
                    value={formik.values.password}
                    placeholder=""
                    inputProps={{ className: "new-password" }}
                    label=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityIcon onClick={handlePasswordVisibility} sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon onClick={handlePasswordVisibility} sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography variant="body2" color="error" gutterBottom>
                      {formik.errors.password}
                    </Typography>
                  )}
                  <label htmlFor="confirm-password" className="input-label confirm-password">
                    RE-ENTER PASSWORD
                  </label>
                  <TextField
                    value={formik.values.confirmedPassword}
                    name="confirmedPassword"
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type={showConfirmPassword ? "text" : "password"}
                    inputProps={{ className: "confirm-password-input" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPassword ? (
                            <VisibilityIcon onClick={handleConfirmPasswordVisibility} sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon onClick={handleConfirmPasswordVisibility} sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)}
                  />
                  {formik.touched.confirmedPassword && formik.errors.confirmedPassword && (
                    <Typography variant="body2" color="error" gutterBottom>
                      {formik.errors.confirmedPassword}
                    </Typography>
                  )}

                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", alignItems: "center", marginTop: "25px" }}
                  >
                    <Button
                      disabled={!formik.values.password}
                      className="submit-btn"
                      label="Submit"
                      icon={null}
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </form>
            )}
            <Typography variant="p" className="guideline-header">
              Password creation guidelines
            </Typography>
            <List sx={{ fontSize: "13px" }}>
              <CustomListItem>
                1.{" "}
                {passwordPolicyTexts?.minLength?.replace(
                  "{minLength}",
                  tenantSvc?.tenantId === "psa" ? 12 : passwordPolicy?.minLength
                )}
                .
              </CustomListItem>
              <CustomListItem className="changePassModalGuideLines"></CustomListItem>
              <CustomListItem>2. The password must contain all 4 of the following characters :</CustomListItem>
              {tenantSvc?.tenantId === "psa" || passwordPolicy?.upperCasePattern ? (
                <CustomListItem sx={{ ml: "15px" }}>i. {passwordPolicyTexts?.uppercase}</CustomListItem>
              ) : null}
              {tenantSvc?.tenantId === "psa" || passwordPolicy?.lowerCasePattern ? (
                <CustomListItem sx={{ ml: "15px" }}>ii. {passwordPolicyTexts?.lowercase}</CustomListItem>
              ) : null}
              {tenantSvc?.tenantId === "psa" || passwordPolicy?.digitPattern ? (
                <CustomListItem sx={{ ml: "15px" }}>iii. {passwordPolicyTexts?.digit}</CustomListItem>
              ) : null}
              {tenantSvc?.tenantId === "psa" || passwordPolicy?.specialCharacterPattern ? (
                <CustomListItem sx={{ ml: "15px" }}>
                  iv. {passwordPolicyTexts?.specialCharacter}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; {passwordPolicyTexts?.specialCharList}
                </CustomListItem>
              ) : null}
              {passwordPolicyTexts?.lastPwdUsed && (
                <CustomListItem>3. {passwordPolicyTexts?.lastPwdUsed}</CustomListItem>
              )}
            </List>
          </RightBannerBoxParent>
        </Grid>
      </Grid>
    </CustomParentBox>
  );
};

CredMgmtSetPassword.propTypes = {
  config: PropTypes.object,
};

export default CredMgmtSetPassword;
