const ErrorMessages = {
  ERR_NETWORK: "Unable to connect to the server. Please try again later.",
  BAD_REQUEST: "There was a problem with the request.",
  UNAUTHORIZED: "Error - Invalid token. You are not authorized to perform this action. Please log in and try again.",
  FORBIDDEN: "You don't have permission to access this resource.",
  NOT_FOUND: "The requested information could not be found. Please contact support.",
  INTERNAL_SERVER_ERROR: "There was a problem on our end. Please try again later or contact support.",
  ERR_UNKNOWN: "An unexpected error occurred. Please try again later or contact support.",
};

const HandleApiError = (error) => {
  if (error.response) {
    const {
      status,
      data: { error_description = "", error_validations = [] },
    } = error.response;

    const errorDescription = error_description || "";
    const validationErrors = error_validations ? error_validations.map((error) => error.message) : [];
    switch (status) {
      case 400:
        if (!errorDescription && validationErrors.length === 0) {
          return ErrorMessages.BAD_REQUEST;
        }
        return (
          <>
            {errorDescription && errorDescription}
            {validationErrors.length > 0 && (
              <ul>
                {validationErrors.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            )}
          </>
        );
      case 401:
        return ErrorMessages.UNAUTHORIZED;
      case 403:
        return ErrorMessages.FORBIDDEN;
      case 404:
        return `${errorDescription}`;
      case 500:
        return ErrorMessages.INTERNAL_SERVER_ERROR;
      case 503:
        return ErrorMessages.ERR_NETWORK;
      default:
        return ErrorMessages.ERR_UNKNOWN;
    }
  } else if (error.request) {
    return ErrorMessages.ERR_NETWORK;
  } else {
    return ErrorMessages.ERR_UNKNOWN;
  }
};

export default HandleApiError;
