import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import {
  Grid,
  InputLabel,
  List,
  ListItem,
  Divider,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ChangePasswordModal.css";
import { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import {
  validationSchema_confirmPassword,
  validationSchema_currentPassword,
  validationSchema_password,
} from "../../helpers/ValidationSchema";
import * as Yup from "yup";
import { CredentialMgmtDataService, OrganizationServiceProvider } from "../../services";
import { UserManagerErrorNotification } from "../../helpers/UserManagerErrorNotification";
import { useParams } from "react-router-dom";
import passwordPolicyTexts from "../../helpers/PasswordPolicyTexts/PasswordPolicyTexts";
import { useTenant } from "../../contexts/TenantService";

const ChangePasswordModal = ({ open, setOpen, onPasswordChangeStatus, organization }) => {
  const organizationService = OrganizationServiceProvider();
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [status, setStatus] = useState({});
  const [passwordPolicy, setPasswordPolicy] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const handleCurrentPasswordVisibility = () => {
    setCurrentShowPassword(!showCurrentPassword);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (organization) {
      organizationService.getOrganizationByID("organizations", organization).then((response) => {
        console.log(response, "ggg");
        setPasswordPolicy(response?.data?.passwordPolicy);
      });
    }
  }, [organization]);

  const { tenant } = useParams();
  const { tenantSvc } = useTenant();
  const theme = `${tenant}-theme`;
  const defaultclass = "wl-parent changePassModal";
  const dynamicClassName = `${defaultclass} ${theme}`;
  const [isLoading, setIsloading] = useState(false);
  const credentialService = CredentialMgmtDataService();
  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      return errors;
    },
    initialValues: { currentPassword: "", password: "", confirmPassword: "" },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      currentPassword: validationSchema_currentPassword.currentPassword,
      password: validationSchema_password(passwordPolicy).password(),
      confirmPassword: validationSchema_confirmPassword.confirmPassword,
    }),
    onSubmit: (values) => {
      setIsloading(true);
      credentialService
        .changePassword("/accounts/operations/change-profile-password", {
          currentPassword: values.currentPassword,
          password: values.password,
          confirmedPassword: values.confirmPassword,
        })
        .then(() => {
          setIsloading(false);
          setOpen(false);
          const updatedStatus = {
            code: 200,
            message: "Password changed successfully",
          };
          setStatus(updatedStatus);
          onPasswordChangeStatus(updatedStatus);
        })
        .catch((error) => {
          setIsloading(false);
          if (error?.response?.status >= 400 && error?.response?.status < 500) {
            const errorStatus = {
              code: error?.response?.status,
              message: UserManagerErrorNotification(error?.response?.data),
            };
            setStatus(errorStatus);
            setShowErrorBanner(true);
          }
          if (error?.response?.status === 500) {
            const errorStatus = { code: 500, message: error?.response?.data?.error_description };
            setStatus(errorStatus);
            setShowErrorBanner(true);
          }
        });
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={dynamicClassName}>
        <Grid container spacing={1} sx={{ borderRadius: "5px 5px 0 0" }} className="changePassModalHeaderWrapper">
          <Grid item xs={9}>
            <Typography variant="h5" className="password-modal-header">
              Change Password
            </Typography>
          </Grid>

          <Grid item={3} className="closeIcon">
            <CloseIcon sx={{ color: "#fff", cursor: "pointer" }} onClick={() => setOpen(false)} />
          </Grid>
        </Grid>
        {isLoading && (
          <div>
            <CircularProgress
              className={`loader-password ${tenantSvc?.tenantId === "psa" ? "psaSpinColor" : "wlSpincolor"}`}
            />
          </div>
        )}
        <form>
          {showErrorBanner ? (
            <Grid container sx={{ mt: 1 }}>
              <Grid xs={1}></Grid>
              <Grid item xs={10}>
                <Alert
                  severity="error"
                  onClose={() => {
                    setShowErrorBanner(false);
                  }}
                >
                  {status.message}
                </Alert>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>
          ) : null}

          <Grid
            container
            spacing={1}
            sx={{ padding: "20px" }}
            style={isLoading ? { opacity: "0.5" } : { opacity: "1" }}
          >
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <InputLabel className="changePasswordModalInputLabel">Current Password</InputLabel>
                  <TextField
                    name="currentPassword"
                    label=""
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.currentPassword}
                    sx={{ width: "220px" }}
                    placeholder="**************"
                    type={showCurrentPassword ? "text" : "password"}
                    inputProps={{ "data-testid": "current-password" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showCurrentPassword ? (
                            <VisibilityIcon onClick={handleCurrentPasswordVisibility} sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon onClick={handleCurrentPasswordVisibility} sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.currentPassword && formik.errors.currentPassword && (
                    <Typography variant="body2" color="error" gutterBottom>
                      {formik.errors.currentPassword}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <InputLabel className="changePasswordModalInputLabel">New Password</InputLabel>
                  <TextField
                    name="password"
                    label=""
                    variant="outlined"
                    inputProps={{ "data-testid": "new-password" }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    sx={{ width: "220px" }}
                    placeholder="**************"
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showNewPassword ? (
                            <VisibilityIcon onClick={handleNewPasswordVisibility} sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon onClick={handleNewPasswordVisibility} sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography variant="body2" color="error" gutterBottom data-testid="error-container">
                      {formik.errors.password}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <InputLabel className="changePasswordModalInputLabel">Confirm Password</InputLabel>
                  <TextField
                    name="confirmPassword"
                    label=""
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    variant="outlined"
                    sx={{ width: "220px" }}
                    inputProps={{ "data-testid": "confirm-password" }}
                    placeholder="**************"
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPassword ? (
                            <VisibilityIcon onClick={handleConfirmPasswordVisibility} sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon onClick={handleConfirmPasswordVisibility} sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <Typography variant="body2" color="error" gutterBottom>
                      {formik.errors.confirmPassword}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={7} className="guidelines-wrapper">
              <Typography variant="h6" className="changePassModalGuideLineHeader">
                Password creation guidelines
              </Typography>
              <List sx={{ fontSize: "13px" }}>
                <ListItem className="changePassModalGuideLines">
                  1.{" "}
                  {passwordPolicyTexts?.minLength?.replace(
                    "{minLength}",
                    tenantSvc?.tenantId === "psa" ? 12 : passwordPolicy?.minLength
                  )}
                  .
                </ListItem>

                <ListItem className="changePassModalGuideLines"></ListItem>

                <ListItem>2. The password must contain all 4 of the following characters :</ListItem>

                {tenantSvc?.tenantId === "psa" || passwordPolicy?.upperCasePattern ? (
                  <ListItem sx={{ ml: "15px" }}>i. {passwordPolicyTexts?.uppercase}</ListItem>
                ) : null}

                {tenantSvc?.tenantId === "psa" || passwordPolicy?.lowerCasePattern ? (
                  <ListItem sx={{ ml: "15px" }}>ii. {passwordPolicyTexts?.lowercase}</ListItem>
                ) : null}
                {tenantSvc?.tenantId === "psa" || passwordPolicy?.digitPattern ? (
                  <ListItem sx={{ ml: "15px" }}>iii. {passwordPolicyTexts?.digit}</ListItem>
                ) : null}
                {tenantSvc?.tenantId === "psa" || passwordPolicy?.specialCharacterPattern ? (
                  <ListItem sx={{ ml: "15px" }}>
                    iv. {passwordPolicyTexts?.specialCharacter}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; {passwordPolicyTexts?.specialCharList}
                  </ListItem>
                ) : null}
                {passwordPolicyTexts?.lastPwdUsed && <ListItem>3. {passwordPolicyTexts?.lastPwdUsed}</ListItem>}
              </List>
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ mt: "15px" }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: " 1px solid #F08791",
                  borderRadius: "20px",
                  color: "#F08791",
                }}
                className="cancel-btn"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={formik.handleSubmit}
                label="Change Password"
                variant="outlined"
                className="change-password-button"
                icon=""
                data-testid="change-password-button"
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
ChangePasswordModal.propTypes = {
  keycloak: PropTypes.object,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  onPasswordChangeStatus: PropTypes.func,
  organization: PropTypes.string,
};
export default ChangePasswordModal;
