import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { CurrentDate } from "../../helpers";
import { useParams } from "react-router-dom";

function GenerateCsv({ rawData, data, type }) {
  if (!rawData || rawData.length === 0) {
    return null;
  }
  const { tenant } = useParams();
  const currentDate = CurrentDate();
  function transformData(data) {
    return data.map((item) => ({
      ...item,
      activation: item.activation === 1 ? "active" : "inactive",
    }));
  }
  const generateCsvHeaders = (headerOrder) => {
    const labels = {
      userName: "User name",
      activation: "Status",
      roleNames: "Role",
      roleName: "Role name",
      organization: "Organization",
      systemRole: "System role name",
      // resource: "Resource",
      firstName: "Given name",
      lastName: "Family name",
    };

    return headerOrder.map((key) => ({
      label: labels[key] || key,
      key,
    }));
  };

  const headerOrderStandard = ["userName", "lastName", "firstName", "activation", "roleNames", "organization"];
  const headerOrderAnalytical = ["userName", "firstName", "lastName", "activation", "roleName", "organization"];

  const csv_standard_headers = generateCsvHeaders(headerOrderStandard);
  const csv_analytical_headers = generateCsvHeaders(headerOrderAnalytical);

  return type === "analytical" ? (
    <CSVLink
      data={transformData(rawData)}
      filename={`WorldlineCIAM-${tenant.toUpperCase()}-RoleAssignmentReport-Analytical-${currentDate}.csv`}
      headers={csv_analytical_headers}
    >
      <Button>Analytical</Button>
    </CSVLink>
  ) : (
    <CSVLink
      data={transformData(data)}
      filename={`WorldlineCIAM-${tenant.toUpperCase()}-RoleAssignmentReport-Standard-${currentDate}.csv`}
      headers={csv_standard_headers}
    >
      <Button>Standard</Button>
    </CSVLink>
  );
}

GenerateCsv.propTypes = {
  rawData: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default GenerateCsv;
