import { MRT_ShowHideColumnsButton, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import "./UserListTable.css";
import { useTranslation } from "react-i18next";
import LeftDrawerList from "../LeftDrawerList/LeftDrawerList";
import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import useGetUsersList from "../../hooks/useGetUsersList/useGetUsersList";
import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import { ListUsersBodySkeleton } from "../ListUsersBodySkeleton";
import { GeneralColumns } from "../../helpers";

function ListData() {
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { tenant } = useParams();
  const { data: allUsers, isError, isFetchingUsers, isLoadingUsers } = useGetUsersList();
  const UserListColumns = GeneralColumns();
  const [columnFilters, setColumnFilters] = useState([]);
  const [total, setTotal] = useState(allUsers?.data?.length);

  useEffect(() => {
    setTotal(allUsers?.data?.length);
  }, [allUsers?.data]);

  useEffect(() => {
    setTotal(table.getFilteredRowModel()?.rows?.length);
  }, [columnFilters]);

  const table = useMaterialReactTable({
    columns: UserListColumns ?? [],
    onColumnFiltersChange: setColumnFilters,
    data: allUsers?.data ?? [],
    paginationDisplayMode: "pages",
    initialState: { showColumnFilters: true },
    state: {
      columnFilters,
      showAlertBanner: isError,
    },
    muiPaginationProps: {
      className: "paginationClasses",
      shape: "rounded",
      variant: "outlined",
      rowsPerPageOptions: [5, 10, 25, 50],
      showFirstButton: false,
      showLastButton: false,
    },

    muiToolbarAlertBannerProps: isError ? { color: "error", children: t("list_user.data.error") } : undefined,
    muiFilterTextFieldProps: {
      placeholder: "Search",
      sx: { m: "0.5rem 0", width: "100%" },
      variant: "outlined",
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: "'Nunito', sans-serif",
      },
    },

    muiTableBodyCellProps: {
      sx: {
        fontFamily: "'Nunito', sans-serif",
      },
    },

    renderTopToolbar: ({ table }) => (
      <Box className="flex_box-space">
        <Grid container spacing={1} className="UserListContainer">
          <Grid item xs={4} sm={4} md={4}>
            <Typography variant="h5">
              <b> {t("list_user.heading")} </b>
              <span>
                - {total}&nbsp;
                {total == 1 ? t("list_user.user.record") : t("list_user.users.records")}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} md={2}></Grid>
          <Grid item xs={3} sm={4} md={6} className="flex-right">
            <Box className="flex_box-center">
              <span className="show-hide-col">
                <VisibilityOutlinedIcon className="selectColumnsIcon-show-eye" />
                <MRT_ShowHideColumnsButton table={table} data-testid="show-hide-btn" />
                <KeyboardArrowDownOutlinedIcon className="selectColumnsIcon-arrow-down" />
              </span>

              <Button
                variant="contained"
                className="add-user-btn"
                onClick={() => navigate(`/ciam/v1/${tenant}/users/create`)}
              >
                Add User
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    ),
  });
  if (isLoadingUsers && isFetchingUsers) {
    return <ListUsersBodySkeleton />;
  }
  return (
    <>
      <Grid container sx={{ mt: "3%" }} spacing={1}>
        <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />
        <Grid item xs={isLeftDrawerOpen ? 0 : 1}>
          <IconButton
            edge="start"
            onClick={() => setIsLeftDrawerOpen(true)}
            sx={{ justifyContent: "flex-start", display: "flex", top: "40px", marginLeft: "40px" }}
          >
            <Typography
              component="img"
              height="15px"
              src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
              className="expand-left-bar-icon"
            />
          </IconButton>
        </Grid>
      </Grid>
      <div
        className="user-list-table"
        style={
          isLeftDrawerOpen ? { marginLeft: "170px", width: "calc(100% - 170px)" } : { marginLeft: "0", width: "100%" }
        }
      >
        <MaterialReactTable table={table} enableColumnFilters />
      </div>
    </>
  );
}

const queryClient = new QueryClient();

const UserListTable = () => (
  <QueryClientProvider client={queryClient}>
    <ListData />
  </QueryClientProvider>
);

ListData.propTypes = {
  cell: PropTypes.object,
};

export default UserListTable;
