import { useState, useCallback, useEffect } from "react";
import { Grid, TextField, Button, Container, Autocomplete, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form } from "formik";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useGetUsersList from "../../hooks/useGetUsersList/useGetUsersList";
import PropTypes from "prop-types";
import { createDateRangeSchema } from "../../helpers/ValidationSchema/ValidationSchema";

const ReportUserDetailsForm = ({ onSubmit, dataLoading, formData, maxMonths }) => {
  const { data: allusers = [], isLoadingUsers } = useGetUsersList();
  const today = dayjs();
  const [dateFieldsTouched, setDateFieldsTouched] = useState({ startDate: false, endDate: false });
  const validationSchema_dateRange6Months = createDateRangeSchema(maxMonths);

  const [initialValues, setInitialValues] = useState({
    selectedUsers: [],
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (formData) {
      setInitialValues({
        selectedUsers: formData.usernames
          ? allusers?.data?.filter((user) => formData.usernames.includes(user.name)) || []
          : [],
        startDate: formData.startDate ? dayjs(formData.startDate) : null,
        endDate: formData.endDate ? dayjs(formData.endDate) : null,
      });
    }
  }, [formData, allusers]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const formattedValues = {
        usernames: values.selectedUsers.length > 0 ? values.selectedUsers.map((user) => user.name) : "",
        startDate: values.startDate ? dayjs(values.startDate).format("YYYY-MM-DD") : "",
        endDate: values.endDate ? dayjs(values.endDate).format("YYYY-MM-DD") : "",
      };
      onSubmit(formattedValues);
      setSubmitting(false);
    },
    [onSubmit]
  );
  const allowedMonsthAgo = today.subtract(maxMonths, "month").startOf("day");

  return (
    <Container className="last-login-report-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema_dateRange6Months}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, errors, isValid, isSubmitting, validateField }) => (
          <Paper sx={{ padding: "0 0px 30px 20px" }}>
            <Form>
              <Grid container spacing={2} justifyContent="flex-start" className="last-login-report-form-wrapper">
                <Grid item xs={12} sm={3}>
                  <label className="input-label" aria-labelledby="users">
                    SELECT USERS
                  </label>
                  <Autocomplete
                    multiple
                    name="selectedUsers"
                    fullWidth
                    loading={isLoadingUsers}
                    loadingText="Loading users..."
                    size="small"
                    limitTags={4}
                    options={allusers?.data || []}
                    getOptionLabel={(option) => option.name || ""}
                    value={values.selectedUsers}
                    sx={{ mt: 1 }}
                    onChange={(event, newValue) => {
                      setFieldValue("selectedUsers", newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select" placeholder="Select" />}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className="date-picker-sd">
                  <label className="input-label" aria-labelledby="startDate">
                    START DATE<span className="required-star">*</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="startDate"
                      label="Enter"
                      sx={{ mt: 1 }}
                      value={values.startDate}
                      onChange={(newDate) => {
                        setFieldValue("startDate", newDate);
                        setDateFieldsTouched((prev) => ({ ...prev, startDate: true }));
                        if (dateFieldsTouched.endDate) {
                          validateField("endDate");
                        }
                      }}
                      slotProps={{ textField: { size: "small" } }}
                      maxDate={today}
                      minDate={allowedMonsthAgo}
                    />
                    {dateFieldsTouched.startDate && errors.startDate && (
                      <p style={{ color: "red", marginTop: "2px", fontSize: "0.75rem" }}>{errors.startDate}</p>
                    )}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={2} className="date-picker-ed">
                  <label className="input-label" aria-labelledby="endDate">
                    END DATE<span className="required-star">*</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="endDate"
                      label="Enter"
                      sx={{ mt: 1 }}
                      value={values.endDate}
                      onChange={(newDate) => {
                        setFieldValue("endDate", newDate);
                        setDateFieldsTouched((prev) => ({ ...prev, endDate: true }));
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      maxDate={today}
                      minDate={values.startDate || allowedMonsthAgo}
                    />
                    {dateFieldsTouched.endDate && errors.endDate && (
                      <p style={{ color: "red", marginTop: "2px", fontSize: "0.75rem" }}>{errors.endDate}</p>
                    )}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1} className="view-btn" sx={{ mt: 1 }}>
                  {isSubmitting || dataLoading ? (
                    <LoadingButton
                      loading
                      sx={{ mt: 2.5 }}
                      variant="outlined"
                      className="report-btn loading-btn"
                      endIcon={<VisibilityIcon />}
                    >
                      View
                    </LoadingButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{ mt: 2.5 }}
                      className="report-btn"
                      disabled={!values.startDate || !values.endDate || !isValid}
                      endIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Paper>
        )}
      </Formik>
      <hr style={{ opacity: 0.2 }} />
    </Container>
  );
};

ReportUserDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  formData: PropTypes.object,
  maxMonths: PropTypes.number,
};

export default ReportUserDetailsForm;
