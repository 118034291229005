const FormatDateTime = (datetimeStr) => {
  const date = new Date(datetimeStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
export const extractDateAndTime = (dateString) => {
  const date = new Date(dateString);

  const optionsDate = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = date.toLocaleDateString(undefined, optionsDate);

  const optionsTime = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false, timeZone: "UTC" };
  const formattedTime = date.toLocaleTimeString(undefined, optionsTime);

  return {
    formattedDate,
    formattedTime,
  };
};
export default FormatDateTime;
