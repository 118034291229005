import { Grid, Skeleton } from "@mui/material";
import useGetUsersList from "../../hooks/useGetUsersList/useGetUsersList";
import { extractDateAndTime } from "../../helpers/FormatDateTime/FormatDateTime";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { UserServiceProvider } from "../../services/UserServiceProvider/UserServiceProvider";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HistoryIcon from "@mui/icons-material/History";

const userCache = {};

function CreatedByInfo({ id }) {
  const userService = UserServiceProvider();
  const { data: allusers = [], isLoadingUsers } = useGetUsersList();
  const [createdBy, setCreatedBy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = allusers?.data?.find((user) => user.id === id);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.creatorOid) {
        setLoading(false);
        return;
      }
      if (userCache[user.creatorOid]) {
        setCreatedBy(userCache[user.creatorOid]);
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await userService.getUserByID("users", user.creatorOid);
        setCreatedBy(response.data);
        userCache[user.creatorOid] = response.data;
      } catch (err) {
        setError(err.message || "An error occurred while fetching the user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user?.creatorOid]);

  if (loading || isLoadingUsers) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) return <div>Error: {error}</div>;
  if (!createdBy || !user?.creatorOid) return null;

  const createdTimestamp = user.createTimestamp;

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="created-by-info">
      <div>
        <CalendarTodayIcon />
        <b>Created</b> <br />
        <span className="created-by-name">
          {`${extractDateAndTime(createdTimestamp).formattedDate}`} at&nbsp;
          {`${extractDateAndTime(createdTimestamp).formattedTime}`}
          {/* {createdBy.givenName && createdBy.familyName && (
            <span>
              &nbsp;by {`${createdBy.givenName}`} {`${createdBy.familyName}`}
            </span>
          )} */}
        </span>
      </div>
    </Grid>
  );
}

function ModifiedByInfo({ id }) {
  const userService = UserServiceProvider();
  const { data: allusers = [], isLoadingUsers } = useGetUsersList();
  const [modifiedBy, setModifiedBy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = allusers?.data?.find((user) => user.id === id);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.modifierOid) {
        setLoading(false);
        return;
      }
      if (userCache[user.modifierOid]) {
        setModifiedBy(userCache[user.modifierOid]);
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await userService.getUserByID("users", user.modifierOid);
        setModifiedBy(response.data);
        userCache[user.modifierOid] = response.data;
      } catch (err) {
        setError(err.message || "An error occurred while fetching the user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user?.modifierOid]);

  if (loading || isLoadingUsers) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) return <div>Error: {error}</div>;
  if (!modifiedBy || !user?.modifierOid) return null;

  const modifiedTimestamp = user?.modifyTimestamp;

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="modified-by-info">
      <div>
        <HistoryIcon />
        <b>Last updated</b> <br />
        <span className="modified-by-name">
          {`${extractDateAndTime(modifiedTimestamp).formattedDate}`} at&nbsp;
          {`${extractDateAndTime(modifiedTimestamp).formattedTime}`}
          {/* {modifiedBy.givenName && modifiedBy.familyName && (
            <>
              &nbsp;by {`${modifiedBy.givenName}`} {`${modifiedBy.familyName}`}
            </>
          )} */}
        </span>
      </div>
    </Grid>
  );
}

export { CreatedByInfo, ModifiedByInfo };

CreatedByInfo.propTypes = {
  id: PropTypes.string.isRequired,
};

ModifiedByInfo.propTypes = {
  id: PropTypes.string.isRequired,
};
