import { useCallback, useState } from "react";
import { RoleServiceProvider } from "../../services";
import BaseReport from "../BaseReport/BaseReport";
import AccessLogTable from "../AccessLogTable/AccessLogTable";
import "./AccessLogReport.css";
import ReportUserDetailsForm from "../ReportUserDetailsForm/ReportUserDetailsForm";

function AccessLogReport() {
  const roleService = RoleServiceProvider();
  const [callDataSvc, setCallDataSvc] = useState(false);
  const [formData, setFormData] = useState({});
  const getDataService = useCallback((roleService, formData) => {
    const { usernames, startDate, endDate } = formData;
    return roleService.getAccessLogDetails("access-log", usernames ?? "", startDate, endDate);
  }, []);
  const handleFormSubmit = (data) => {
    setFormData(data);
    setCallDataSvc(true);
  };

  return (
    <BaseReport
      title="Access log"
      getDataService={(roleService) => getDataService(roleService, formData)}
      tableComponent={AccessLogTable}
      formComponent={(props) => (
        <ReportUserDetailsForm {...props} onSubmit={handleFormSubmit} formData={formData} maxMonths={1} />
      )}
      roleService={roleService}
      callDataSvc={callDataSvc}
      transformData={false}
      setCallDataSvc={setCallDataSvc}
    />
  );
}

export default AccessLogReport;
